/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500;100&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;100&display=swap'); */

.cg-toggle{    
    margin-right: 10px;
}

body {
    background-color: #072441;
    color: white;
    /* font-family: 'Poppins', sans-serif;
    font-weight: 400; */
}

.cg-font{
    /* font-family: 'Poppins', sans-serif;
    font-weight: 100; */
}

.cg-dark {
background-color: #13436b;
color: white;
}

.cg-light  {
    background-color: white;
    color: black;
}

/* style="background-color: rgb(19, 70, 110); color: white; */
.form-control.cg-light:focus {
    color: #000000;
    background-color: #ffffff;
}
.form-control.cg-dark:focus {
    color: #fff;
    background-color: #14446b;
}

.form-control.cg-light::placeholder{
            color: rgb(78, 78, 78);
            opacity: 1; /* Firefox */
}

.form-control.cg-dark::placeholder{
            color: rgb(170, 170, 170);
            opacity: 1; /* Firefox */
}

.alert-success {
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc;
}

::after {
    filter: invert(1);
}